body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden; /* Prevents scrolling within the iframe */
}
  
#decimal_video {
  width: 100%; /* Makes the video take the full width of its container */
  height: 100%; /* Makes the video take the full height of its container */
  position: absolute; /* Positions it relative to the nearest positioned ancestor */
}
  