body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden; 
}
  
#decimal_video {
  width: 100%; 
  height: 100%; 
  position: absolute; 
}
  